import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../../components/ExploreContainer';
import {Md5} from 'ts-md5/dist/md5';
import '../../theme/Login.css';

const Login: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <form>

            <IonItem >
            <IonInput name ="username" placeholder="User ID" value="487" >
            </IonInput>
            </IonItem>

            <IonItem >
            <IonInput name ="password" type="password" placeholder="Hash" value={Md5.hashStr("hash")}>
            </IonInput>
            </IonItem>
            <br/>

            <div text-center="true">
            <IonButton >Login</IonButton>
            </div>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Login;
