import { IonSelectOption, IonListHeader, IonSplitPane, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonMenuButton, IonTextarea, IonPage, IonTitle, IonToolbar, IonLabel, IonItemDivider, IonList, IonSelect, IonIcon } from '@ionic/react';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useParams } from 'react-router';
import React, { useState, Component, KeyboardEventHandler } from 'react';
import ExploreContainer from '../../components/ExploreContainer';
import {Md5} from 'ts-md5/dist/md5';
import '../../theme/Default.css';
import ChatUsers from '../../components/ChatUsers';
import { State } from 'ionicons/dist/types/stencil-public-runtime';
import { hammerSharp, archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';


let message_id = 0;
class ChatMessage {
  // sample {"message_id":2,"type":"allserverstatus","user_id":0,"hash":""}
  type: string; //"validateuser"
  message_id: number;
  user_id: number;
  hash: string;

  constructor(call:string){
    this.type = call;
    this.message_id = 1;
    this.user_id = -1;
    this.hash = "";
  }
}

class HeavyChatMessage extends ChatMessage
{
    // sample {"room_id":"1","heavy_instance_id":1,"message_id":3,"type":"join","user_id":0,"hash":""}
    heavy_instance_id:number;
    game_id: number;
    room_id: string;
  
    constructor(call:string){
      super(call);
      this.heavy_instance_id = 1;
      this.game_id = 1;
      this.room_id = "1";
    }
}

class JoinMessage extends HeavyChatMessage {
  // sample {"room_id":"1","heavy_instance_id":1,"message_id":3,"type":"join","user_id":0,"hash":""}

  constructor(channelNum?:number){
    super("join");
    if(channelNum != null)
    {
      this.heavy_instance_id = channelNum!;
    }
  }
}

class LoginMessage extends HeavyChatMessage {
  //  {"game_id":1,"unlinked_user_id":0,"room_id":"1","heavy_instance_id":1,"instance_id":1,"message_id":1,"type":"validateuser","user_id":0,"hash":""}
  unlinked_user_id: number | undefined;

  constructor(){
    super("validateuser");
    this.unlinked_user_id = this.user_id;
  }
}

class UnmuteMessage extends ChatMessage {
  //{"action":"remove","message_id":7,"type":"mutelist","user_id":0,"hash":""}
  action: string;

  constructor(){
    super("mutelist");
    this.action = "remove";
  }
}

class MuteMessage extends ChatMessage {
  //{"action":"remove","message_id":7,"type":"mutelist","user_id":0,"hash":""}
  action: string;

  constructor(){
    super("mutelist");
    this.action = "add";
  }
}

class PingMessage extends ChatMessage {
    // {"ping":0, "message_id":3, "type":"keepalive","user_id":0,"hash":""}
    // user_id is other player's id
    // ping = this.lastPingTime (always 0) -- game does not change value
    ping: number;
    
    constructor(){
      super("keepalive");
      this.ping = 0;
    }
}

class BanUserMessage extends ChatMessage {
  other_user_id: number;

  constructor(){
    super("ban");
    this.other_user_id = 0;
  }
}
class KickUserMessage extends ChatMessage {
  other_user_id: number;

  constructor(){
    super("kick");
    this.other_user_id = 0;
  }
}

class ReportUserMessage extends ChatMessage {
  other_user_id: number;
  message: string;

  constructor(){
    super("report");
    this.other_user_id = 0;
    this.message = "";
  }
}
class GetRoomsMessage extends ChatMessage {
  room_id: number;

  constructor(){
    super("getrooms");
    this.room_id = 1; // GameID
  }
}

class SendMessageMessage extends ChatMessage {
  room_id: number;
  message: string;

  constructor(){
    super("chat");
    this.room_id = 1;
    this.message = "";
  }
}


class AllServerStatusResponse {
  rooms: ChatRoom[];
  message: string;
  max_connections: number;
  total_connections: number;

  constructor(){
    this.rooms = [];
    this.message = "";
    this.max_connections = 0;
    this.total_connections = 0;
  }
}
class ChatRoom {
  connected_users: number;
  instance_id: number;
  name: string;
  room_id: any;

  constructor(){
    this.connected_users = 0;
    this.instance_id = 0;
    this.name = "";
    this.room_id = 1;
  }
}

class TestExtendedLoginMessage extends HeavyChatMessage {
  //  {"game_id":1,"unlinked_user_id":0,"room_id":"1","heavy_instance_id":1,"instance_id":1,"message_id":1,"type":"validateuser","user_id":0,"hash":""}
  unlinked_user_id: number | undefined;
  highest_available_difficulty: number;

  constructor(){
    super("validateuser");
    this.unlinked_user_id = this.user_id;
    this.highest_available_difficulty = 10;
  }
}

class ChatUser {
  color: string | undefined;
  user_id: number;
  name: string;
  //this.AdminLevel = defData.Int("admin_level", 0);

  constructor(username:string, userid:number, color:string | undefined){
    this.color = color;
    this.name = username;
    this.user_id = userid;
  }
}

let client:W3CWebSocket;
let chatUsersList:ChatUser[] = [];
let currentChatRoom = 0;
let username = "";
let muteList = [];
let roomsList:ChatRoom[] = [];

const Chat: React.FC = () => {

  //{Md5.hashStr("hash")}
  const { name } = useParams<{ name: string; }>();
  const [text, setText] = useState<string>();
  const [messages = "", setChatMessages] = useState<string>();
  const [chatUsersListState, setChatUsersList] = useState<ChatUser[]>();
  const [numChatUsers, setNumChatUsers] = useState<number>(0);
  const [user_id = 2300411 , setUserID] = useState<number>();
  const [hash = "fab4d22ab64a65a599bc3b7e6e5ee8a3", setHash] = useState<string>();
  const [default_user_id = 2300411 , setDefaultUserID] = useState<number>();
  const [default_hash = "fab4d22ab64a65a599bc3b7e6e5ee8a3", setDefaultHash] = useState<string>();
  const [roomsListState = [], setRoomsList] = useState<ChatRoom[]>();
  const [selectedRoomState = 1, setSelectedRoom] = useState<number>();
  const [isConnectedState = false, setConnectedState] = useState<boolean>();

  const Login = () =>{
    if(isConnectedState)
    {
      onLogin(user_id, hash);
    }
  }

  const defaultLogin = () =>{
    if(isConnectedState)
    {
      onLogin(default_user_id, default_hash);
    }
  }

  const kickUser = (otherUserId: number) =>{
    let thisMessage = new KickUserMessage();
    thisMessage.user_id = user_id!;
    thisMessage.hash = hash!;
    thisMessage.message_id = ++message_id;
    thisMessage.other_user_id = otherUserId;
    client.send(JSON.stringify(thisMessage));
  }
  
  const addChatUser = (user: ChatUser) =>{
    chatUsersList.push(new ChatUser(user.name, user.user_id, "#0000FF"));
  }

  const removeChatUser = (userid: number) =>{
    chatUsersList.forEach( (item, index) => {
      if(item.user_id === userid)
      {
         chatUsersList.splice(index,1);
         return; // returns on first as users in list are unique
      }
    });    
  }

  const closeClient = () =>
  {
    client.close();
    chatUsersList = [];
    setChatUsersList(chatUsersList);
  }
  
  const openClient = () => {
    client = new W3CWebSocket('ws://ps1.idlechampions.com:8887/websocket/', undefined, undefined, {headers: "{'User-Agent': 'BestHTTP', 'Accept-Encoding':'gzip, identity'}"});
    message_id = 0;    
    client.onopen = () => {
      console.log('Websocket client Connected');
      setConnectedState(true);
    }
  
    client.onmessage = (message: any) => 
    {
      
      const dataFromServer = JSON.parse(message.data);
      if(dataFromServer.type == "validateuser")
      {
        setUserID(dataFromServer.user_id);
        username = dataFromServer.name;
        dataFromServer.mute_list?.forEach((element: any) => {
          muteList.push(element);
        });
      }
      if(!(dataFromServer.messages === undefined))
      {
        let userCount = 1;
        chatUsersList = [];
        let myUser = new ChatUser(username, user_id!, "#0000FF");
        addChatUser(myUser);
        dataFromServer.messages.forEach( (element: any)  => {
          let foundDuplicate = false;
          chatUsersList.forEach(chatUser => {
            if(chatUser.user_id == element.user_id){ foundDuplicate = true; return;}            
          });
          if(foundDuplicate) {return;}
          let user = new ChatUser(element.name, element.user_id, element.color);
          addChatUser(user);
          userCount++;
        });
        setNumChatUsers(userCount);
        setChatUsersList(chatUsersList);
      }
      if(dataFromServer.heavy_instance_id != null)
      {
        currentChatRoom = dataFromServer.heavy_instance_id;
      }
      if(dataFromServer.type == "chat")
      {
        let userName = "";
        chatUsersList.forEach(element => {
          if(element.user_id == dataFromServer.user_id)
          {
            userName = element.name;
          }
        });
        if(userName == "")
        {
          userName = dataFromServer.user_id.toString();
        }
        if(username == userName)
        {
          setChatMessages(messages => {
            if(messages == undefined)
            { return "[[" + userName + "]]: " + dataFromServer.message; }
            else
            { return messages + "\r\n[[" + userName + "]]: " + dataFromServer.message; }
          });
        }
        else
        {
          setChatMessages(messages => {
            if(messages == undefined)
            { return "[" + userName + "]: " + dataFromServer.message; }
            else
            { return messages + "\r\n[" + userName + "]: " + dataFromServer.message; }
          });
        }
      }
      if(dataFromServer.type == "leave")
      {
        removeChatUser(dataFromServer.user_id);
        setNumChatUsers(chatUsersList.length);
        setChatUsersList([]);
        setChatUsersList(chatUsersList);
      }
      if(dataFromServer.type == "join")
      {
        let user = new ChatUser(dataFromServer.name, dataFromServer.user_id, dataFromServer.color);
        addChatUser(user);
        setNumChatUsers(chatUsersList.length);
        setChatUsersList([]);
        setChatUsersList(chatUsersList);
      }
      if(dataFromServer.type == "allserverstatus")
      {
        roomsList = [];
        dataFromServer.all_server_statuses[0].rooms.forEach((element: ChatRoom) => {
          let room = new ChatRoom();
          room.room_id = parseInt(element.room_id);
          room.name = element.name;
          room.instance_id = element.instance_id;
          room.connected_users = element.connected_users;
          roomsList.push(room)
        });
        // setRoomsList(dataFromServer.all_server_statuses[0].rooms);
        setRoomsList([]);
        setRoomsList(roomsList);
      }

      //messages = messages + "\r\n" + dataFromServer.user_id + ": " + dataFromServer.message ;
      console.log(dataFromServer);
    }

    client.onclose = () => {
      setNumChatUsers(0);
      chatUsersList = [];
      setChatUsersList(chatUsersList);
      setConnectedState(false);
      console.log('Websocket client closed');
    }
  }

  const sendChatMessage = (call:string) => {
    let chatMessage = new ChatMessage(call);
  }
  
  const joinChannel = (num: number) =>
  {
    let channel = new JoinMessage(num);
    // chatUsersList = [];
    // setChatUsersList(chatUsersList => (chatUsersList));
    client.send(JSON.stringify(channel));
  }

  const onLogin = (userid: number, hash:string) => {
    let loginMessage = new TestExtendedLoginMessage();
    loginMessage.user_id = loginMessage.unlinked_user_id = userid!;
    loginMessage.hash = hash!;
    loginMessage.message_id = ++message_id;
    loginMessage.heavy_instance_id = 3;
    client.send(JSON.stringify(loginMessage));
  
    let serverStatusMessage = new ChatMessage("allserverstatus");
    serverStatusMessage.user_id = userid!;
    serverStatusMessage.hash = hash!;
    serverStatusMessage.message_id = ++message_id;
    client.send(JSON.stringify(serverStatusMessage));
  }
  
  const sendMessage = (message: string | undefined | null, e: any) => {
    if(e.key === 'Enter')
    {
      e.preventDefault();
      if(!(message === undefined || message === null))
      {
        let thisMessage = new SendMessageMessage();
        thisMessage.user_id = user_id!;
        thisMessage.hash = hash!;
        thisMessage.message_id = ++message_id;
        thisMessage.message = message;
        client.send(JSON.stringify(thisMessage));
      }
      setText("");
    }
  }
  
  const sendText = (txt: string | undefined) =>
  {
    setText(txt);
  }


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <form>
              <IonItem >
              <IonInput name ="username" placeholder="User ID" value="" onInput={(e) => setUserID(parseInt(e.currentTarget.value!.toString()))} >
              </IonInput>
              </IonItem>

              <IonItem >
              <IonInput name ="password" type="password" placeholder="Hash" value="" onInput={(e) => setHash(e.currentTarget.value?.toString())}>
              </IonInput>
              </IonItem>
              <br/>

              <IonItem class="center">
              <IonButton disabled={!isConnectedState} onClick={() => Login()}>Login</IonButton>
              </IonItem>
          </form>

          <IonList>
          <IonItemDivider>Chat:</IonItemDivider>
          <IonItem>
            <IonTextarea readonly rows={20} cols={20} value={messages}></IonTextarea>
          </IonItem>

          <IonItem>
            <IonLabel position="floating">Send Message</IonLabel>
            <IonTextarea name='messsageText' placeholder="Message" value={text}  onKeyPress={(e) =>sendMessage(text, e)} onIonChange={e => setText(e.detail.value!)}> </IonTextarea>
          </IonItem>

          <div>
            <IonButton disabled={isConnectedState} onClick={() => openClient()}>Connect</IonButton>
            <IonButton disabled={!isConnectedState} onClick={() => defaultLogin()}>Default Login</IonButton>
            <IonButton disabled={!isConnectedState} onClick={() => closeClient()}>Disconnect</IonButton>
          </div>
          <div>
            <div>
            <IonItemDivider>Select Room: </IonItemDivider>
            <IonSelect value={selectedRoomState} okText="Okay" cancelText="Dismiss" onIonChange={e => setSelectedRoom(e.detail.value!)}>
              {roomsListState?.map((room, index) => {
              return (
                    <IonSelectOption key={room.instance_id} value={room.instance_id}>{room.name}</IonSelectOption >
                );
              })}
            </IonSelect>
            </div>
            <IonButton disabled={!isConnectedState} onClick={() => joinChannel(selectedRoomState!)}>Join Selected</IonButton>
            <IonButton disabled={!isConnectedState} onClick={() => joinChannel(2)}>Quick Join 2</IonButton>
            <IonButton disabled={!isConnectedState} onClick={() => joinChannel(3)}>Quick Join 3</IonButton>

          </div>
          

        </IonList>
        <IonListHeader>Chat Users ({numChatUsers}) </IonListHeader> 
        <IonContent>
        <IonList id="chat-users-list">
          
          {chatUsersListState?.map((user, index) => {
            return (
                <IonItem detail={false} key={user.user_id}>
                  <IonIcon ios={hammerSharp} md={hammerSharp} onClick={e => kickUser(user.user_id)} />&nbsp;<IonLabel>{user.name}</IonLabel>
                </IonItem>
            );
          })}
        </IonList>
      </IonContent>
      
      </IonContent>
    </IonPage>
  );
};

export default Chat;
